import { Component, Inject, ViewChild } from "@angular/core"

import { Observable } from "rxjs"
import { tap } from "rxjs/operators"

import { GridComponent, LayerService, LoadFields, ModalLayer } from "@anzar/core"

import { Partner, PartnerRepoSource } from "@backend/partner.api"

import { partnerCardinalityLabel, PartnerService } from "../common.module"
import { PARTNER_ID, PartnerSheetComponent } from "./partner-sheet.component"

const PARTNER_FIELDS: LoadFields<Partner> = ["id", "name", "is_active", "traits", "impls"]

@Component({
    selector: ".eur-partner-grid",
    templateUrl: "./partner-grid.component.pug"
})
export class PartnerGridComponent {
    @ViewChild("grid", { static: true }) public readonly grid: GridComponent

    public readonly fields = PARTNER_FIELDS

    public constructor(
        @Inject(PartnerRepoSource) public readonly source: PartnerRepoSource,
        @Inject(PartnerService) public readonly partnerSvc: PartnerService,
        @Inject(LayerService) private readonly layerSvc: LayerService
    ) {}

    /**
     * Handles the tap event on a partner row in the grid.
     * Opens the partner details window and reloads the grid upon closure.
     *
     * @param partner - The partner object representing the tapped row.
     */
    public onRowTap(partner: Partner) {
        this.showPartner(partner.id).subscribe(_ => {
            this.grid.reaload()
        })
    }

    public newPartner() {
        this.createPartner().subscribe(_ => {
            this.grid.reaload()
        })
    }

    public showPartner(partenrId: number) {
        return this._showPartnerWnd(partenrId).pipe(tap(this.partnerSvc.reset.bind(this.partnerSvc)))
    }

    public createPartner() {
        return this._showPartnerWnd().pipe(tap(this.partnerSvc.reset.bind(this.partnerSvc)))
    }

    private _showPartnerWnd(partnerId?: number): Observable<Partner> {
        return new Observable<Partner>(subscriber => {
            const behavior = new ModalLayer({
                backdrop: { type: "filled", hideOnClick: false },
                trapFocus: true,
                closeable: false,
                rounded: 5,
                elevation: 10,
                position: {
                    align: {
                        vertical: "top",
                        horizontal: "center"
                    },
                    constraint: {
                        ref: "viewport",
                        inset: "32 16 16 16"
                    }
                }
            })

            const ref = this.layerSvc.createFromComponent(PartnerSheetComponent, behavior, null, [
                { provide: PARTNER_ID, useValue: partnerId }
            ])

            ref.subscribe(event => {
                if (event.type === "hiding") {
                    subscriber.complete()
                } else if (event.type === "save") {
                    subscriber.next(event.data)
                }
            })

            ref.show()

            return () => {
                ref?.hide()
            }
        })
    }

    public cardinality(partner: Partner): string {
        return this.partnerSvc
            .getCardinality(partner)
            .map(c => partnerCardinalityLabel[c])
            .join(", ")
    }
}
