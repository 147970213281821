import { Component, Inject } from "@angular/core"

import { BehaviorSubject, filter, map, shareReplay, switchMap, take } from "rxjs"

import { FileDownloadService, ToastService } from "@anzar/core"

import { AuthService } from "@pyzar/auth.module"

import { Partner } from "@backend/__anzar_rpc_output"

import { BACKEND_BASE_URL, PartnerService } from "../common.module"
import { ProductCreateWndService } from "./product-create-wnd.component"
import { ProductOfferUploaderService } from "./product-offer-uploader.component"

@Component({
    selector: "eur-product-offers-screen",
    templateUrl: "./product-offers.screen.pug",
    providers: [ProductOfferUploaderService, ProductCreateWndService]
})
export class ProductOffersScreen {
    public readonly canManageProducts$ = this.authSvc.hasPermission("product.manage")

    public readonly currentPartner$ = new BehaviorSubject<Partner | null>(null)

    public readonly isEditable$ = this.currentPartner$.pipe(
        map(partner => partner && this.partnerSvc.hasTrait(partner, "SupplierStockUploadTrait")),
        shareReplay(1)
    )

    constructor(
        @Inject(AuthService) private readonly authSvc: AuthService,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService,
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(ProductOfferUploaderService) private readonly uploader: ProductOfferUploaderService,
        @Inject(ProductCreateWndService) private readonly createProd: ProductCreateWndService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {}

    doUploadProducts(event: Event) {
        this.currentPartner$
            .pipe(
                filter(partner => !!partner),
                take(1),
                switchMap(partner => this.uploader.show(event.target as HTMLElement, partner.id))
            )
            // TODO: reload
            .subscribe()
    }

    doDownloadProducts() {
        this.currentPartner$
            .pipe(
                filter(partner => !!partner),
                take(1),
                switchMap(partner =>
                    this.downloader.download(`${this.baseUrl}/get/supplier-link/${partner.id}`).pipe(
                        this.toast.handleFileDownload({
                            align: "bottom center",
                            message: "Termékek exportálása..."
                        })
                    )
                )
            )
            .subscribe()
    }

    onPartnerChange(partner: Partner) {
        this.currentPartner$.next(partner)
    }

    doCreateProduct() {
        this.createProd.show().subscribe(supplierProduct => {
            // TODO: reload
            // if (supplierProduct) {
            //     this.grid.source.filter = { id: supplierProduct.product_id } as any
            // }
        })
    }
}
