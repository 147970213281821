import { Component, Inject, Input, OnChanges, Output, SimpleChanges } from "@angular/core"

import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from "rxjs"
import { filter, shareReplay, take } from "rxjs/operators"

import { Partner } from "@backend/partner.api"

import { PartnerCardinality, partnerCardinalityLabel, PartnerService, TraitSelector } from "../../partner.service"

export interface PartnerWithCardinality {
    type: PartnerCardinality
    partner: Partner
}

export interface PartnerGroup {
    title: string
    partners: PartnerWithCardinality[]
}

@Component({
    selector: ".eur-partner-switch",
    templateUrl: "./partner-switch.component.pug"
})
export class PartnerSwitchComponent implements OnChanges {
    @Input() public readonly traits: TraitSelector[]
    @Input() public readonly cardinalities: PartnerCardinality[]

    private readonly traits$ = new BehaviorSubject<TraitSelector[]>([])
    private readonly cardinalities$ = new BehaviorSubject<PartnerCardinality[]>([])

    public partners$: Observable<Partner[]> = this.traits$.pipe(
        switchMap(traits => {
            if (traits.length === 0) {
                return of([] as Partner[])
            }

            return this.partnerSvc.partnersByTrait(...traits).pipe(
                map(partners =>
                    partners.sort((a, b) => {
                        const at = traits.map(t => this.partnerSvc.hasTrait(a, t))
                        const bt = traits.map(t => this.partnerSvc.hasTrait(b, t))
                        return at.indexOf(true) - bt.indexOf(true)
                    })
                )
            )
        }),
        shareReplay(1)
    )

    private _grouped$ = this.partners$.pipe(
        map(partners => {
            const grouped: { [key in PartnerCardinality]?: PartnerWithCardinality[] } = {}

            for (const partner of partners) {
                const cardinality = this.partnerSvc.getCardinality(partner)
                for (const type of cardinality) {
                    if (!grouped[type]) {
                        grouped[type] = []
                    }
                    grouped[type].push({ type, partner })
                }
            }

            return grouped
        }),
        shareReplay(1)
    )

    public readonly grouped$ = combineLatest({
        grouped: this._grouped$,
        cardinalities: this.cardinalities$
    }).pipe(
        map(({ grouped, cardinalities }) =>
            (Object.keys(grouped) as Array<keyof typeof grouped>)
                .filter(k => cardinalities.includes(k as PartnerCardinality))
                .reduce<typeof grouped>((acc, k) => {
                    acc[k] = grouped[k]
                    return acc
                }, {})
        ),
        shareReplay(1)
    )

    public blocks$ = this.grouped$.pipe(
        map(grouped => {
            const blocks: PartnerGroup[] = []
            const partnerIds = new Set<Partner["id"]>()

            for (const [k, v] of Object.entries(grouped)) {
                if (partnerIds.has(v[0].partner.id)) {
                    continue
                }
                partnerIds.add(v[0].partner.id)

                blocks.push({
                    title: partnerCardinalityLabel[k as PartnerCardinality],
                    partners: v.sort((a, b) => a.partner.sequence - b.partner.sequence)
                })
            }

            return blocks
        }),
        shareReplay(1)
    )

    // public readonly suppliers$ = this.partnerSvc.suppliers$
    // public readonly merchants$ = this.partnerSvc.merchants$

    public set selectedPartner(val: PartnerWithCardinality) {
        const old = this._selectedPartner.value
        if (!old || !val || old.partner.id !== val.partner.id) {
            this._selectedPartner.next(val)
        }
    }
    public get selectedPartner(): PartnerWithCardinality {
        return this._selectedPartner.value
    }

    @Output("selectedPartner")
    public readonly _selectedPartner = new BehaviorSubject<PartnerWithCardinality>(null)

    public constructor(@Inject(PartnerService) private readonly partnerSvc: PartnerService) {
        this.blocks$
            .pipe(
                filter(v => v.length > 0),
                take(1)
            )
            .subscribe(blocks => {
                this.selectedPartner = blocks[0].partners[0]
            })
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ("traits" in changes) {
            this.traits$.next(changes.traits.currentValue)
        }

        if ("cardinalities" in changes) {
            this.cardinalities$.next(changes.cardinalities.currentValue)
        }
    }

    public doSelect(partner: PartnerWithCardinality) {
        this.selectedPartner = partner
    }

    public isSelected(partner: PartnerWithCardinality) {
        const selected = this.selectedPartner
        return selected && selected.partner.id === partner.partner.id
    }
}
