import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core"

import { PartnerCardinality, TraitSelector } from "../../partner.service"
import { PartnerWithCardinality } from "./partner-switch.component"

@Component({
    selector: ".eur-partner-switch-panel",
    templateUrl: "./partner-switch-panel.component.pug"
})
export class PartnerSwitchPanelComponent {
    @Input() public readonly traits: TraitSelector[]
    @Input() public readonly cardinalities: PartnerCardinality[] = [
        PartnerCardinality.Supplier,
        PartnerCardinality.Merchant
    ]

    @ContentChild("content", { static: true, read: TemplateRef })
    public readonly content: TemplateRef<PartnerWithCardinality>

    public selectedPartner: PartnerWithCardinality

    @Output() public readonly partnerChange = new EventEmitter<PartnerWithCardinality["partner"]>()

    onSelectPartner(selected: PartnerWithCardinality) {
        if (selected) {
            this.partnerChange.next(selected.partner)
        }
        this.selectedPartner = selected
    }
}
