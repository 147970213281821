import { NgModule } from "@angular/core"
import { Invoice, InvoiceSynced, InvoiceDesired, InvoiceItem, InvoiceItemShippingItem, InvoiceAncestor, InvoiceHistory, Payment, PaymentUpload, PaymentUploadEntry, InvoiceNotification, Spending, CurrencyConversionRate, UploadPaymentsTrait, InvoicingTrait, UploadPaymentResult, PaymentRepo, InvoiceRepo, InvoiceRepoSource } from "./__anzar_rpc_output"

export { Invoice, InvoiceSynced, InvoiceDesired, InvoiceItem, InvoiceItemShippingItem, InvoiceAncestor, InvoiceHistory, Payment, PaymentUpload, PaymentUploadEntry, InvoiceNotification, Spending, CurrencyConversionRate, UploadPaymentsTrait, InvoicingTrait, UploadPaymentResult, PaymentRepo, InvoiceRepo, InvoiceRepoSource }


@NgModule({
    providers: [
        InvoiceRepoSource,
        InvoiceRepo,
        PaymentRepo
    ],
})
export class AccountingApi {}