import { Component, EventEmitter, Inject, Input, Output } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import { LoadFields } from "@anzar/core"

import { ShippingMethodRepoSource } from "@backend/carrier.api"
import { CountryRepoSource } from "@backend/common.api"
import { PaymentMethod } from "@backend/enum.api"
import { BillingInfo, Order, OrderRepo, OrderRepoSource, ShippingInfo } from "@backend/order.api"

import { PriceInputComponent } from "../common.module"

export function customerFormModel(isBilling: boolean) {
    const fields: { [key: string]: FormControl | FormGroup } = {
        id: new FormControl(undefined),
        name: new FormControl(null, [Validators.required]),
        country: new FormControl("HU", [Validators.required]),
        city: new FormControl(null, [Validators.required]),
        postcode: new FormControl(null, [Validators.required]),
        shire: new FormControl(null),
        address: new FormControl(null, [Validators.required]),
        phones: new FormControl(null, [Validators.required]),
        // TODO: kötelező, ha el kell küldeni e-mail-ben a számlát
        emails: new FormControl(null),
        note: new FormControl()
    }

    if (isBilling) {
        fields["tax_number"] = new FormControl(null)
        fields["payment_method"] = new FormControl(null, [Validators.required])
        fields["proforma_need"] = new FormControl(null)
        fields["proforma_amount"] = new FormControl(undefined)
        fields["fulfillment_days"] = new FormControl(0)
        fields["due_days"] = new FormControl(0)
    } else {
        fields["contact_name"] = new FormControl(null)
        fields["shipping_method_id"] = new FormControl(null, [Validators.required])
        fields["price"] = PriceInputComponent.formModel(undefined, "Nincs megdava a szállítási ár")
    }

    return new FormGroup(fields)
}

const CUSTOMER_SELECT_FIELDS: LoadFields<Order> = [
    "id",
    { shipping_info: ["id", "name", "address_formatted"] },
    { billing_info: ["id", "name", "tax_number", "address_formatted"] }
]

const CUSTOMER_FULL_FIELDS: LoadFields<Order> = ["shipping_info", "billing_info"]

export interface CustomerAutoFill {
    shipping_info: ShippingInfo
    billing_info: BillingInfo
}

@Component({
    selector: "eur-order-customer-form",
    templateUrl: "./customer-form.component.pug"
})
export class OrderCustomerFormComponent {
    @Input() public isShipping: boolean = false
    @Input() public isBilling: boolean = false
    @Input() public form: FormGroup
    @Input() public merchantId: number

    @Output() public autoFill = new EventEmitter<CustomerAutoFill>()

    public readonly paymentMethodSrc = PaymentMethod.DATA
    public readonly customerFields = CUSTOMER_SELECT_FIELDS

    constructor(
        @Inject(CountryRepoSource) public readonly countrySrc: CountryRepoSource,
        @Inject(OrderRepo) public readonly orderRepo: OrderRepo,
        @Inject(OrderRepoSource) public readonly orderSrc: OrderRepoSource,
        @Inject(ShippingMethodRepoSource) public readonly shippingMethodSrc: ShippingMethodRepoSource
    ) {}

    onCustomerSelect(value: ShippingInfo | BillingInfo) {
        if (value?.id) {
            this.orderRepo.get({ id: value.id }, { loadFields: CUSTOMER_FULL_FIELDS }).subscribe(value => {
                this.autoFill.next(value)
                if (this.isShipping) {
                    this.fill(value.shipping_info)
                } else {
                    this.fill(value.billing_info)
                }
            })
        }
    }

    fill(data: ShippingInfo | BillingInfo) {
        const formValue = {
            id: null,
            name: data.name,
            country: data.country,
            city: data.city,
            postcode: data.postcode,
            shire: data.shire,
            address: data.address,
            phones: (data.phones || []).join(", "),
            emails: (data.emails || []).join(", "),
            note: data.note
        } as any

        if (data instanceof ShippingInfo) {
            formValue["contact_name"] = data.contact_name
            formValue["shipping_method_id"] = data.shipping_method?.id
        } else {
            formValue["tax_number"] = data.tax_number
            formValue["payment_method"] = data.payment_method.value
            formValue["proforma_need"] = data.proforma_need
            formValue["proforma_amount"] = data.proforma_amount
            formValue["fulfillment_days"] = data.fulfillment_days
            formValue["due_days"] = data.due_days
        }

        console.log(formValue)

        this.form.patchValue(formValue)
    }
}
