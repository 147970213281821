import { Component, Inject, Input } from "@angular/core"

import { LoadFields } from "@anzar/core"

import { SupplierProduct, SupplierProductRepoSource } from "@backend/__anzar_rpc_output"

import { ProductSheetService } from "./product-sheet.component"

const FIELDS: LoadFields<SupplierProduct> = ["id", "product_id", "name"]

@Component({
    selector: "eur-product-offer-grid",
    templateUrl: "./product-offer-grid.component.pug"
})
export class ProductOfferGridComponent {
    @Input() public partnerId: number

    readonly fields = FIELDS

    constructor(
        @Inject(SupplierProductRepoSource) readonly supplierProductSrc: SupplierProductRepoSource,
        @Inject(ProductSheetService) private readonly sheetSvc: ProductSheetService
    ) {}

    public showProduct(id: number) {
        this.sheetSvc.show(id).subscribe()
    }
}
