import { Component, Inject } from "@angular/core"

import { FileDownloadService, ToastService } from "@anzar/core"

import { BACKEND_BASE_URL } from "../common.module"

@Component({
    templateUrl: "./payment-upload.screen.pug"
})
export class PaymentUploadScreen {
    constructor(
        @Inject(FileDownloadService) private readonly downloader: FileDownloadService,
        @Inject(ToastService) private readonly toast: ToastService,
        @Inject(BACKEND_BASE_URL) private readonly baseUrl: string
    ) {}

    doDownload() {
        this.downloader
            .download(`${this.baseUrl}/get/stat/payments.xlsx`)
            .pipe(this.toast.handleFileDownload({ align: "bottom center", beginMsg: "Kiegyenlítési adatok letöltése" }))
            .subscribe()
    }
}
