import { Component, Inject, Input } from "@angular/core"
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms"

import {
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    of,
    ReplaySubject,
    shareReplay,
    Subscription,
    switchMap,
    take
} from "rxjs"

import { ExternalCategoryRepoSource } from "@backend/category.api"
import { Partner } from "@backend/partner.api"
import { ManufacturerRepoSource, Product, ProductRepo } from "@backend/product.api"
import { Stock, StockRepo } from "@backend/product.api"
import {
    SupplierProduct,
    SupplierProductRepo,
    Warehouse,
    WarehouseRepo,
    WarehouseStock,
    WarehouseStockRepo
} from "@backend/supplier.api"

import { PartnerService, PriceInputComponent } from "../common.module"

export interface FormValues {
    product_id: number
    supplier_id: number
    supplier_product_id: number
    name: string
    manufacturer_sku: string
    eans: string
    is_active: boolean
    is_refurbished: boolean
    category_id: number
    manufacturer_id: number
    description: string
    warranty: number
    wslp: object
    msrp: object
    warehouse: Array<{ warehouse_id: number; qty: number; name: string }>
}

export interface AllStock {
    stock: Stock
    partner: Partner
}

@Component({
    selector: "eur-supplier-product-form",
    templateUrl: "./supplier-product-form.component.pug"
})
export class SupplierProductFormComponent {
    static formModel(defaults?: Partial<FormValues>) {
        return new FormGroup({
            product_id: new FormControl(defaults?.product_id),
            supplier_id: new FormControl(defaults?.supplier_id),
            supplier_product_id: new FormControl(defaults?.supplier_product_id),
            name: new FormControl(null, [Validators.required]),
            manufacturer_sku: new FormControl(null, [Validators.required]),
            eans: new FormControl(null, [Validators.required]),
            is_active: new FormControl(null),
            is_refurbished: new FormControl(false),
            is_virtual: new FormControl(false),
            manufacturer_id: new FormControl(null, [Validators.required]),
            category_id: new FormControl(null, [Validators.required]),
            description: new FormControl(),
            warranty: new FormControl(),
            warehouse: new FormArray([]),
            wslp: PriceInputComponent.formModel(null, "Nagyker ár megadás kötelező"),
            msrp: PriceInputComponent.formModel()
        })
    }

    @Input()
    public set form(val: FormGroup) {
        if (this._form !== val) {
            this._form = val
            this._formSub?.unsubscribe()
            this._formSub = val.valueChanges.subscribe(this.formValues$)
            this.formValues$.next(val.value)
        }
    }
    public get form(): FormGroup {
        return this._form
    }
    private _form: FormGroup
    private _formSub: Subscription

    readonly formValues$ = new ReplaySubject<FormValues>(1)

    get warehouseControl() {
        return this.form.get("warehouse") as FormArray
    }

    private readonly ref$ = this.formValues$.pipe(
        distinctUntilChanged(
            (a, b) =>
                // eslint-disable-next-line eqeqeq
                a.product_id == b.product_id &&
                // eslint-disable-next-line eqeqeq
                a.supplier_id == b.supplier_id &&
                // eslint-disable-next-line eqeqeq
                a.supplier_product_id == b.supplier_product_id
        ),
        shareReplay(1)
    )

    private readonly productData$ = this.ref$.pipe(
        switchMap(values => {
            if (values.supplier_product_id) {
                return this.supplierProductRepo
                    .get({ id: values.supplier_product_id })
                    .pipe(map(convertSupplierProduct))
            } else if (values.supplier_id && values.product_id) {
                return this.supplierProductRepo
                    .search({
                        filter: { partner_id: values.supplier_id, product_id: values.product_id },
                        begin: 0,
                        count: 1
                    })
                    .pipe(
                        switchMap(result => {
                            if (result && result.length > 0) {
                                return of(convertSupplierProduct(result[0]))
                            } else {
                                return this.productRepo.get({ id: values.product_id }).pipe(map(convertProduct))
                            }
                        })
                    )
            } else if (values.product_id) {
                return this.productRepo.get({ id: values.product_id }).pipe(map(convertProduct))
            } else {
                return of(null)
            }
        }),
        shareReplay(1)
    )

    private readonly warehouses$ = this.ref$.pipe(
        switchMap(values =>
            values.supplier_id
                ? this.warehouseRepo.search({
                      filter: { partner_id: values.supplier_id },
                      order: { priority: "desc" }
                  })
                : of([] as Warehouse[])
        ),
        shareReplay(1)
    )

    private readonly stocks$ = combineLatest({ ref: this.ref$, prod: this.productData$ }).pipe(
        map(({ ref, prod }) => {
            return { ...ref, ...prod }
        }),
        switchMap(values =>
            values.supplier_product_id
                ? this.warehouseStockRepo.search({ filter: { product_id: values.supplier_product_id } })
                : of([] as WarehouseStock[])
        ),
        shareReplay(1)
    )

    private readonly categoryId$ = this.ref$.pipe(
        switchMap(values =>
            this.supplierProductRepo.get_category_id({
                supplier_id: values.supplier_id,
                product_id: values.product_id,
                supplier_product_id: values.supplier_product_id
            })
        ),
        shareReplay(1)
    )

    readonly patchData$: Observable<Partial<FormValues>> = combineLatest({
        formValues: this.formValues$,
        prodValues: this.productData$,
        warehouses: this.warehouses$,
        stocks: this.stocks$,
        categoryId: this.categoryId$
    }).pipe(
        map(({ formValues, prodValues, warehouses, stocks, categoryId }) => {
            if (!prodValues) {
                return null
            }

            return {
                ...prodValues,
                category_id: formValues.category_id || categoryId,
                warehouse: warehouses.map(v => {
                    return {
                        warehouse_id: v.id,
                        qty: stocks.find(s => s.warehouse_id === v.id)?.total || 0,
                        name: v.name
                    }
                })
            }
        }),
        shareReplay(1)
    )

    readonly allStocks$ = this.ref$.pipe(
        switchMap(values =>
            values.product_id
                ? this.stockRepo.search({
                      filter: { product_id: values.product_id, partner_id: { neq: values.supplier_id } }
                  })
                : of([] as Stock[])
        ),
        shareReplay(1)
    )

    readonly otherOffers$: Observable<AllStock[]> = combineLatest({
        allSotcks: this.allStocks$,
        partners: this.partnerSvc.allPartners$
    }).pipe(
        map(({ allSotcks, partners }) =>
            allSotcks
                .map(stock => {
                    return { stock, partner: partners.find(v => v.id === stock.partner_id)! }
                })
                .filter(v => v.partner.is_active)
                .sort((a, b) => {
                    if (a.stock.wslp?.net && b.stock.wslp?.net) {
                        return a.stock.wslp.net - b.stock.wslp.net
                    } else if (a.stock.wslp?.net) {
                        return -1
                    } else if (b.stock.wslp?.net) {
                        return 1
                    }
                    return 0
                })
        ),
        shareReplay(1)
    )

    constructor(
        @Inject(ExternalCategoryRepoSource) public readonly extCatSrc: ExternalCategoryRepoSource,
        @Inject(ProductRepo) private readonly productRepo: ProductRepo,
        @Inject(SupplierProductRepo) private readonly supplierProductRepo: SupplierProductRepo,
        @Inject(WarehouseRepo) private readonly warehouseRepo: WarehouseRepo,
        @Inject(WarehouseStockRepo) private readonly warehouseStockRepo: WarehouseStockRepo,
        @Inject(ManufacturerRepoSource) public readonly manufacturerSrc: ManufacturerRepoSource,
        @Inject(StockRepo) private readonly stockRepo: StockRepo,
        @Inject(PartnerService) private readonly partnerSvc: PartnerService
    ) {
        this.patchData$
            .pipe(
                filter(v => !!v),
                take(1)
            )
            .subscribe(value => {
                console.log(value)

                this.warehouseControl.clear()
                for (const wh of value.warehouse) {
                    this.warehouseControl.push(
                        new FormGroup({
                            name: new FormControl(wh.name),
                            warehouse_id: new FormControl(wh.warehouse_id, [Validators.required]),
                            qty: new FormControl(wh.qty)
                        })
                    )
                }

                this.form.patchValue(value)
            })

        this.otherOffers$.subscribe(v => console.log(v))
    }
}

/**
 * Converts a SupplierProduct object into a partial FormValues object.
 *
 * @param {SupplierProduct} sprod - The SupplierProduct object to convert.
 * @return {Partial<FormValues>} The converted partial FormValues object.
 */
function convertSupplierProduct(sprod: SupplierProduct): Partial<FormValues> {
    return {
        supplier_id: sprod.partner_id,
        product_id: sprod.product_id,
        supplier_product_id: sprod.id,
        name: sprod.name,
        manufacturer_sku: sprod.manufacturer_sku,
        eans: (sprod.eans || []).join(", "),
        description: sprod.description,
        wslp: { net: sprod.wslp },
        msrp: { net: sprod.msrp },
        is_active: sprod.is_active,
        manufacturer_id: sprod.manufacturer_id,
        warranty: sprod.warranty_duration?.duration
    }
}

function convertProduct(prod: Product): Partial<FormValues> {
    return {
        product_id: prod.id,
        name: prod.name,
        manufacturer_sku: prod.sku,
        eans: (prod.eans || []).join(", "),
        is_active: true,
        manufacturer_id: prod.manufacturer_id
    }
}
